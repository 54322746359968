<template>
  <div class="ticket-result-number">
    <div class="w-100 mb-2 mx-0 row" v-for="(result, index) in item.lottery_result" :key="'result_c' + index">
      <p class="text-uppercase prizeTitle d-lg-flex col-lg-2 p-0 text-right">
        {{ isMax3D ? rewardMax3DLabel[index] : rewardMax4DLabel[index] }}
      </p>
      <div
        class="ticket-result-row justify-content-start d-flex flex-wrap pl-lg-3 col-lg-10"
        :class="{
          'col-lg-9': !isFullWidthResult,
          'col-12': isFullWidthResult
        }"
      >
        <div
          class="mr-3 ticket-result-row d-flex"
          v-for="(number, _index) in result"
          :key="'result_p' + index + '_' + _index + '_' + number"
        >
          <NumberItem
            v-for="(char, idx) in number.split('')"
            :key="'numberp' + index + '_' + number + '_' + idx"
            :number="+char"
            :isCustomForResult="true"
            class="spacingNumber"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NumberItem from './NumberItem';
export default {
  components: { NumberItem },
  props: {
    index: {
      type: Number
    },
    rewardMax3DLabel: {
      type: Array
    },
    rewardMax4DLabel: {
      type: Array
    },
    item: {
      type: Object
    },
    isMax3D: {
      type: Boolean
    },
    isFullWidthResult: {
      type: Boolean,
      default: false
    }
  }
  // mounted() {
  //   console.log(this.item);
  // }
};
</script>
<style lang="scss" scoped>
.table-column {
  margin: 0.3rem 0.75rem;
}
.prizeTitle {
  font-weight: 600;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  color: #333;
}

.ticket-result-number {
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: -4px;
}

.ticket-result-number {
  flex-direction: column;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .ticket-result-row {
    padding: 4px 0;
  }

  .ticketItemListNumberInGroup {
    color: #ffffff;
  }
}

.boxPrize {
  background-color: rgba(247, 237, 226, 0.5);
  padding: 0.3rem 0.75rem;
  border-radius: 5px;

  .line {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    border-bottom: 1px solid #ede8d3;
    margin-bottom: 0.35rem;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0.35rem;
    }
  }
}
.lineHeight15 {
  line-height: 1.5;
}
.lotteryResult2 {
  background-color: #fff;
  padding: 1rem 1.25rem;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.myListNumber {
  margin: -0.6rem 0;
}

.ticketItemListNumberInGroup {
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 50%;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 5px;
  &.correct {
    background-color: #d68c00;
  }
}

.boxFitlerResult {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
}

.btnResult {
  background-color: #d68c00;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.125rem;
  padding: 0.375rem 0.9375rem;
  width: 80px;
  text-align: center;
  border-radius: 3px;
  margin-right: 12px;
  &.even {
    margin-right: 0.75rem;
  }
}
// .bgActive {
//   background-color: #f7f2ec;
// }
.customResult {
  padding-bottom: 10px;
  border: solid #ffffff;
  border-width: 0 0 1px 0;
}
.overflowX-auto {
  overflow-x: auto;
}

.pl-header {
  padding-left: 0.75rem;
}
.pr-header {
  padding-right: 0.75rem;
}

.spacingNumber {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .customResultDate {
    margin-left: auto;
    margin-right: 10px;
  }
  .prizeTitle {
    font-weight: 600;
    height: auto;
  }
  .ticket-result-number {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .customResultDate {
    margin-left: auto;
    margin-right: 10px;
  }
  .prizeTitle {
    font-weight: 600;
    height: auto;
  }
  .ticketItemListNumber {
    width: 34px;
    height: 34px;
    font-size: 17px;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .ticket-result-number {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
