<template>
  <b-card>
    <b-container fluid>
      <!-- Header -->
      <b-row class="table-filter">
        <b-col cols="12">
          <h4 class="b-card-title">Game result</h4>
        </b-col>

        <!-- Filter game  -->
        <b-col cols="1">
          <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
            <b-form-select
              id="screen-filter"
              v-model="filterGame"
              :options="allGameFilter"
              @change="onChangeFilterGame"
            >
              <!-- <template v-slot:first>
                <b-form-select-option :value="null">All</b-form-select-option>
              </template> -->
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- Filter game  -->
        <b-col cols="2">
          <b-form-group id="game-result-return-reward" label="Return reward" label-for="game-result-return-reward">
            <b-form-select
              id="game-result-return-reward"
              v-model="returnRewardFilterValue"
              :options="returnRewardFilter"
              @change="onChangeReturnReward"
            >
              <template v-slot:first>
                <b-form-select-option :value="null">All</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- Filter period -->
        <b-col cols="2">
          <b-form-group id="input-name-filter" label="Period Name" label-for="name-filter">
            <b-form-input
              id="name-filter"
              v-model="filter_period"
              :disabled="!!filter_from_period || !!filter_to_period"
              type="text"
              placeholder="Search period name..."
              @input="onFilterName"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- filter date from -->
        <!-- <b-col>
          <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
            <datePicker
              v-model="filter_from"
              :config="dpOptions"
              :disabled="isFilterByGame"
              @dp-change="fetchData"
            ></datePicker>
          </b-form-group>
        </b-col> -->

        <!-- filter date to -->
        <!-- <b-col>
          <b-form-group id="input-to-date" label="To Date:" label-for="to-date">
            <datePicker
              v-model="filter_to"
              :config="dpOptions"
              :disabled="isFilterByGame"
              @dp-change="fetchData"
            ></datePicker>
          </b-form-group>
        </b-col>
      </b-row> -->
        <!-- Table -->

        <!-- filter date from -->
        <b-col cols="2">
          <b-form-group id="input-from-period" label="From period:" label-for="from-period">
            <b-form-input
              v-model="filter_from_period"
              :disabled="!!filter_period"
              type="text"
              placeholder="From period..."
              @input="onFilterName"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- filter date to -->
        <b-col cols="2">
          <b-form-group id="input-to-period" label="To period:" label-for="to-period">
            <b-form-input
              v-model="filter_to_period"
              :disabled="!!filter_period"
              type="text"
              placeholder="To period..."
              @input="onFilterName"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="text-right pt-4">
          <b-button variant="primary" @click="handlePopupRegeneratePeriod">Generate</b-button>
        </b-col>
      </b-row>

      <b-table
        striped
        :fields="headers"
        :items="items"
        :busy="isLoading"
        :current-page="currentPage"
        responsive
        bordered
        class="elevation-1 data-table text-center"
      >
        <template v-slot:cell(lottery_time)="item">
          <span>{{ formatDateForViewFunc(item.value) }}</span>
        </template>
        <template v-slot:cell(actions)="item">
          <div class="d-flex align-items-center justify-content-center">
            <span :id="`view_result_${item.item.id}`">
              <v-icon
                v-if="item.item.lottery_result.length"
                class="text-success mr-3"
                @click="handleViewItem(item.item)"
                >mdi-eye</v-icon
              >
            </span>
            <b-tooltip :target="`view_result_${item.item.id}`" triggers="hover">View result</b-tooltip>
            <span :id="`regenarate_result_${item.item.id}`">
              <v-icon
                v-if="!item.item.lottery_result.length && new Date() - new Date(item.item.lottery_time) > 0"
                class="text-danger mr-3"
                size="medium"
                @click="handleRegenerateResult(item.item)"
                >fas fa-undo-alt</v-icon
              >
            </span>
            <b-tooltip :target="`regenarate_result_${item.item.id}`" triggers="hover">Regenarate result</b-tooltip>
            <span :id="`edit_result_${item.item.id}`">
              <v-icon
                v-if="new Date() - new Date(item.item.lottery_time) > 0"
                class="text-info mr-3"
                size="medium"
                @click="handleEdit(item.item)"
                >fas fa-edit</v-icon
              >
            </span>
            <b-tooltip :target="`edit_result_${item.item.id}`" triggers="hover">Edit result</b-tooltip>
          </div>
        </template>
        <!-- !item.item.lottery_result.length &&  -->
        <template v-slot:cell(status)="item">
          <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
          <b-badge class="mr-1" v-else variant="danger">Disabled</b-badge>
        </template>
        <template v-slot:cell(returned_reward)="item">
          <div v-if="item.value === 1" :id="`returned_reward_${item.item.id}`">
            <v-icon size="medium" class="text-success">far fa-calendar-plus</v-icon>
          </div>
          <div v-else :id="`returned_reward_${item.item.id}`">
            <v-icon title="Not return reward" size="medium" class="text-danger">far fa-calendar-minus</v-icon>
          </div>
          <b-tooltip :target="`returned_reward_${item.item.id}`" triggers="hover">{{
            item.value === 1 ? 'Returned reward' : 'Not Return reward'
          }}</b-tooltip>
        </template>
      </b-table>
      <!-- Footer -->
      <b-row>
        <b-col cols="5">
          {{ footerTableInfo }}
        </b-col>
        <b-col style="display: flex; justify-content: flex-end">
          <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
            <label class="mr-sm-2" for="per-page">Rows per page: </label>
            <b-form-select
              id="per-page"
              v-model="perPage"
              size="md"
              :options="[5, 10, 15, 20]"
              @change="fetchData"
            ></b-form-select>
          </b-form>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
            v-on:input="fetchData"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      modal-class="modalDetail"
      v-model="modalGenerate"
      :title="modalGenerateTitle"
      ok-title="Generate"
      header-close-content=""
      ok-only
      @ok="handleRegeneratePeriod"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-form-group id="input-start-period" label="Start period:" label-for="start-period">
              <b-form-input
                name="start-period"
                v-model="startPeriod"
                data-vv-as="start-period"
                v-validate="{ required: true }"
                type="text"
                placeholder="Start period..."
              ></b-form-input>
              <span v-show="errors.has('start-period')" class="is-danger-text position-relative">{{
                errors.first('start-period')
              }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group id="input-start-time" label="Start time:" label-for="start-time">
              <datePicker v-model="startTime" :config="startTimeOptions"></datePicker>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group id="input-day-count" label="Day count:" label-for="day-count">
              <b-form-input
                name="day-count"
                v-model="dayCount"
                data-vv-as="day-count"
                v-validate="{ required: true, min: 1 }"
                type="number"
                placeholder="2"
              ></b-form-input>
              <span v-show="errors.has('day-count')" class="is-danger-text position-relative">{{
                errors.first('day-count')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      modal-class="modalDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
      :scrollable="true"
    >
      <b-container>
        <div class="text-center" v-if="isLoadingModal">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else>
          <b-row class="justify-content-center">
            <b-col cols="12" class="mb-4 text-center">
              <p class="mb-2 lotteryInfo">
                Kỳ quay thưởng <b>#{{ viewItem.period_no }}</b> ngày
                <b>{{ formatDateForViewFunc(viewItem.lottery_time) }}</b>
              </p>
            </b-col>

            <div class="col-12" v-if="filterGame && viewItem.lottery_result.length">
              <div class="d-flex flex-wrap justify-content-center" v-if="gameHanlder[filterGame].handler === 'keno'">
                <NumberItem
                  v-for="(item, index) in viewItem.lottery_result.slice(0, 10)"
                  :key="index"
                  :number="+item"
                  :isCustomForResult="true"
                  class="spacingNumber"
                />
                <div class="w-100" />
                <NumberItem
                  v-for="(item, index) in viewItem.lottery_result.slice(10)"
                  :key="index + 10"
                  :number="+item"
                  :isCustomForResult="true"
                  class="spacingNumber"
                />
              </div>

              <div v-if="['power', 'mega'].includes(gameHanlder[filterGame].handler)">
                <div class="d-flex customMr5 justify-content-center">
                  <NumberItem
                    v-for="(item, index) in viewItem.lottery_result"
                    :key="index"
                    :number="+item"
                    :isCustomForResult="true"
                    class="spacingNumber"
                    :class="{
                      specialNumber: index === viewItem.lottery_result.length - 1 && index > 5
                    }"
                  />
                </div>
                <hr class="my-4" />
                <div class="row justify-content-lg-between mb-2">
                  <div class="col-5">
                    <p class="text-uppercase color-grey pl-header">Giải thưởng</p>
                  </div>
                  <div class="col-2 px-0">
                    <p class="text-right text-uppercase color-grey d-none d-md-block">Số lượng giải</p>
                    <p class="text-right text-uppercase color-grey d-block d-md-none">SL giải</p>
                  </div>
                  <div class="col-5">
                    <p class="text-right text-uppercase color-grey pr-header">Giá trị</p>
                  </div>
                </div>
                <div class="boxPrize" v-if="prize.length">
                  <div v-for="(item, index) in prize" :key="index" class="row justify-content-lg-between m-0">
                    <div class="col-4 px-0">
                      <p class="font-weight-600">{{ item.title }}</p>
                      <!-- <p class="color-grey">{{ item.note }}</p> -->
                    </div>
                    <div class="col-3 px-0">
                      <p class="text-right font-weight-600">{{ formatPrice(item.count, '') }}</p>
                    </div>

                    <div class="col-5 px-0">
                      <p :class="{ 'color-red-1': item.jackpot }" class="text-right font-weight-bold">
                        {{ item.amount ? formatPrice(item.amount) : '---' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="['max3d', 'max3d+', 'max4d'].includes(gameHanlder[filterGame].handler)">
                <UIResultMaxListNumber
                  :item="viewItem"
                  :isMax3D="!(gameHanlder[filterGame].handler === 'max4d')"
                  :rewardMax3DLabel="rewardMax3DLabel"
                  :rewardMax4DLabel="rewardMax4DLabel"
                  class="lotteryResultMaxHome font-15"
                  :isFullWidthResult="true"
                />
                <hr class="my-4" />
                <div class="row justify-content-lg-between mb-2">
                  <div class="col-6">
                    <p class="text-uppercase color-grey pl-header">Giải thưởng</p>
                  </div>
                  <div class="col-6">
                    <p class="text-right text-uppercase color-grey d-none d-md-block">Số lượng giải</p>
                    <p class="text-right text-uppercase color-grey d-block d-md-none">SL giải</p>
                  </div>
                  <!-- <div class="col-5">
                    <p class="text-right text-uppercase color-grey pr-header">Giá trị</p>
                  </div> -->
                </div>
                <div class="boxPrize" v-if="prize.length">
                  <div v-if="gameHanlder[filterGame].handler === 'max4d'">
                    <div class="line" v-for="(dataPrize, idx) in prize" :key="'prize_' + idx">
                      <p class="font-weight-600">{{ dataPrize.name }}</p>
                      <div
                        v-for="(data, index) of dataPrize.data"
                        :key="index"
                        class="row justify-content-lg-between m-0 mb-2 p-0"
                      >
                        <div class="col-6">
                          <p class="mt-2">{{ data.title }}</p>
                        </div>
                        <div class="col-6 col-lg-4">
                          <p class="text-right">{{ formatPrice(data.count, '') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(item, index) in prize" :key="index" class="row justify-content-lg-between m-0">
                      <div class="col-6">
                        <p class="font-weight-600">{{ item.title }}</p>
                        <!-- <p class="color-grey">{{ item.note }}</p> -->
                      </div>
                      <div class="col-6">
                        <p class="text-right font-weight-600">{{ formatPrice(item.count, '') }}</p>
                      </div>

                      <!-- <div class="col-5 px-0">
                        <p :class="{ 'color-red-1': item.jackpot }" class="text-right font-weight-bold">
                          {{ item.amount ? formatPrice(item.amount) : '---' }}
                        </p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      modal-class="modalDetail"
      v-model="dialogUpdate"
      size="lg"
      :title="modalTitleUpdate"
      ok-title="Close"
      header-close-content=""
      ok-only
      :scrollable="true"
      @hide="handleHideUpdate"
    >
      <b-container>
        <div class="text-center" v-if="isLoadingModal">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else>
          <b-row class="justify-content-center">
            <b-col cols="12" class="mb-4 text-center">
              <p class="mb-2 lotteryInfo">
                Kỳ quay thưởng <b>#{{ editedItem.period_no }}</b> ngày
                <b>{{ formatDateForViewFunc(editedItem.lottery_time) }}</b>
              </p>
            </b-col>

            <div class="col-12" v-if="filterGame">
              <div v-if="gameHanlder[filterGame].handler === 'keno'">
                <div class="d-flex flex-wrap justify-content-center mh-90">
                  <NumberItem
                    v-for="(item, index) in sortEditNumber.slice(0, 10)"
                    :key="index"
                    :number="+item"
                    :isCustomForResult="true"
                    class="spacingNumber"
                  />
                  <div class="w-100" />
                  <NumberItem
                    v-for="(item, index) in sortEditNumber.slice(10)"
                    :key="index + 10"
                    :number="+item"
                    :isCustomForResult="true"
                    class="spacingNumber"
                  />
                </div>
                <div class="controlNumber controlNumber--keno">
                  <hr class="my-4" />
                  <div class="content">
                    <div @click="handleNumberUpdate(item)" v-for="(item, index) in listAllNumber" :key="index">
                      <NumberItem
                        :isActive="editedItem.lottery_result.includes(mixNumber(item))"
                        :number="+item"
                        :isCustomForResult="editedItem.lottery_result.includes(mixNumber(item))"
                        class="spacingNumber pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="['power', 'mega'].includes(gameHanlder[filterGame].handler)">
                <div class="d-flex customMr5 justify-content-center mh-50">
                  <NumberItem
                    v-for="(item, index) in sortEditNumberPower"
                    :key="index"
                    :number="+item"
                    :isCustomForResult="true"
                    class="spacingNumber"
                  />

                  <NumberItem
                    v-if="specialNumberValue"
                    :number="+specialNumberValue"
                    :isCustomForResult="true"
                    class="spacingNumber"
                    :class="{
                      specialNumber: true
                    }"
                  />
                  <!-- specialNumberValue -->
                </div>

                <div class="controlNumber controlNumber--powerMega">
                  <hr class="my-4" />
                  <b-form-checkbox
                    v-if="gameHanlder[filterGame].handler === 'power'"
                    class="pointer"
                    v-model="specialNumber"
                    >Chọn số đặc biệt</b-form-checkbox
                  >
                  <div class="content">
                    <div @click="handleNumberUpdate(item)" v-for="(item, index) in listAllNumber" :key="index">
                      <NumberItem
                        :isActive="editedItem.lottery_result.includes(mixNumber(item))"
                        :number="+item"
                        :isCustomForResult="editedItem.lottery_result.includes(mixNumber(item))"
                        class="spacingNumber pointer"
                        :class="{
                          specialNumber: specialNumberValue == mixNumber(item)
                        }"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div class="row justify-content-lg-between mb-2">
                  <div class="col-5">
                    <p class="text-uppercase color-grey pl-header">Giải thưởng</p>
                  </div>
                  <div class="col-2 px-0">
                    <p class="text-right text-uppercase color-grey d-none d-md-block">Số lượng giải</p>
                    <p class="text-right text-uppercase color-grey d-block d-md-none">SL giải</p>
                  </div>
                  <div class="col-5">
                    <p class="text-right text-uppercase color-grey pr-header">Giá trị</p>
                  </div>
                </div>
                <div class="boxPrize" v-if="prize.length">
                  <div v-for="(item, index) in prize" :key="index" class="row justify-content-lg-between m-0">
                    <div class="col-4 px-0">
                      <p class="font-weight-600">{{ item.title }}</p>
                      <!-- <p class="color-grey">{{ item.note }}</p> -->
                    </div>
                    <div class="col-3 px-0">
                      <div class="text-right font-weight-600">{{ formatPrice(item.count, '') }}</div>
                      <b-form-input
                        size="md"
                        v-model="item.count"
                        type="number"
                        placeholder=""
                        @update="handleChangePrize"
                      ></b-form-input>
                    </div>

                    <div class="col-5 px-0">
                      <p
                        v-if="['jackpot_1', 'jackpot_2'].includes(item.key)"
                        :class="{ 'color-red-1': item.jackpot }"
                        class="text-right font-weight-bold"
                      >
                        {{ item.amount ? formatPrice(item.amount) : '---' }}
                        <b-form-input
                          size="md"
                          v-model="item.amount"
                          type="number"
                          placeholder=""
                          class="w-75 ml-auto"
                          @update="handleChangePrize"
                        ></b-form-input>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="['max3d', 'max3d+', 'max4d'].includes(gameHanlder[filterGame].handler)">
                <UIResultMaxListNumberEdit
                  :item="editedItem"
                  :isMax3D="!(gameHanlder[filterGame].handler === 'max4d')"
                  :rewardMax3DLabel="rewardMax3DLabel"
                  :rewardMax4DLabel="rewardMax4DLabel"
                  class="lotteryResultMaxHome"
                  :isFullWidthResult="true"
                  ref="resultMaxListNumberEdit"
                />
                <hr class="my-4" />
                <div class="boxPrize" v-if="prize.length">
                  <div v-if="gameHanlder[filterGame].handler === 'max4d'">
                    <div class="line" v-for="(dataPrize, idx) in prize" :key="'prize_' + idx">
                      <p
                        v-b-toggle="`collapse-max-${idx}`"
                        class="font-weight-600 d-flex border-bottom border-left pl-2"
                      >
                        {{ dataPrize.name }}
                        <v-icon size="x-small" class="ml-auto">flaticon2-down</v-icon>
                      </p>
                      <b-collapse :id="`collapse-max-${idx}`">
                        <div
                          v-for="(data, index) of dataPrize.data"
                          :key="index"
                          class="row justify-content-lg-between m-0 mb-2 p-0 pb-2"
                        >
                          <div class="col-6">
                            <p class="mt-2">{{ data.title }}</p>
                          </div>
                          <div class="col-6 col-lg-4">
                            <div class="text-right font-weight-600 pb-1">{{ formatPrice(data.count, '') }}</div>
                            <b-form-input
                              size="md"
                              v-model="data.count"
                              type="number"
                              placeholder=""
                              class="w-75 ml-auto"
                              @update="handleChangePrize"
                            ></b-form-input>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(item, index) in prize" :key="index" class="row justify-content-lg-between m-0 pb-2">
                      <div class="col-6">
                        <p class="font-weight-600">{{ item.title }}</p>
                      </div>
                      <div class="col-6">
                        <div class="text-right font-weight-600 pb-1">{{ formatPrice(item.count, '') }}</div>
                        <b-form-input
                          size="md"
                          v-model="item.count"
                          type="number"
                          placeholder=""
                          class="w-75 ml-auto"
                          @update="handleChangePrize"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </b-container>

      <template v-slot:modal-footer>
        <div>
          <button class="btn btn-secondary" @click="dialogUpdate = false">Cancle</button>
          <button class="btn btn-primary ml-2 px-5" :disabled="isDisableSaveUpdate" @click="handleSaveUpdate">
            Save
          </button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import ApiService from '@/common/api.service.js';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import _ from 'lodash';
import { convertArrayToObject } from '@/utils/function';
import NumberItem from './components/NumberItem';
import UIResultMaxListNumber from './components/UIResultMaxListNumber';
import UIResultMaxListNumberEdit from './components/UIResultMaxListNumberEdit';
import Swal from 'sweetalert2';
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import { formatDateForView } from '@/utils/time.js';
// import axios from 'axios';

export default {
  components: {
    datePicker,
    NumberItem,
    UIResultMaxListNumber,
    UIResultMaxListNumberEdit
  },
  data() {
    return {
      filterGame: null,
      allGameFilter: [],
      returnRewardFilterValue: null,
      returnRewardFilter: [
        {
          text: 'Returned',
          value: 1
        },
        {
          text: 'Not Returned',
          value: 0
        }
      ],
      isFilterByGame: true,
      isLoading: true,
      perPage: 10,
      rows: 0,
      currentPage: 1,
      filter_period: '',

      headers: [
        { label: 'ID', key: 'id' },
        { label: 'Period', key: 'period_no' },
        { label: 'Lottery Time', key: 'lottery_time' },
        { label: 'Lottery Result', key: 'lottery_result' },
        { label: 'Returned Reward', key: 'returned_reward', class: 'text-center' },
        { label: 'Status', key: 'status', class: 'text-center' },
        { label: 'Actions', key: 'actions', class: 'text-center' }
      ],
      items: [],
      filter_from: null,
      filter_to: null,
      filter_from_period: '',
      filter_to_period: '',
      dpOptions: {
        format: 'DD/MM/YYYY',
        sideBySide: true
      },
      startTimeOptions: {
        format: 'YYYY-MM-DD HH:mm',
        sideBySide: true
      },
      dialog: false,
      dialogUpdate: false,

      editedIndex: -1,
      viewItemIndex: -1,
      isLoadingModal: false,
      viewItem: {
        id: '',
        lottery_result: [],
        lottery_time: '',
        period_no: '',
        returned_reward: '',
        status: ''
      },
      editedItem: {
        id: '',
        lottery_result: [],
        lottery_time: '',
        period_no: '',
        returned_reward: '',
        status: ''
      },
      rewardMax3DLabel: ['giải nhất', 'giải nhì', 'giải ba', 'giải khuyến khích'],
      rewardMax4DLabel: ['giải nhất', 'giải nhì', 'giải ba', 'giải khuyến khích 1', 'giải khuyến khích 2'],
      listAllNumber: [],
      prize: [],
      prizeTitle: {
        jackpot_1: 'Giải Jackpot',
        jackpot_2: 'Giải Jackpot 2',
        first_prize: 'Giải Nhất',
        second_prize: 'Giải Nhì',
        third_prize: 'Giải Ba',
        max3d: {
          1: 'Giải Nhất',
          2: 'Giải Nhì',
          3: 'Giải Ba',
          4: 'Giải Khuyến Khích'
        },
        'max3d+': {
          1: 'Giải Nhất',
          2: 'Giải Nhì',
          3: 'Giải Ba',
          4: 'Giải Tư',
          5: 'Giải Năm',
          6: 'Giải Sáu',
          7: 'Giải Bảy'
        },
        max4d: {
          1: 'Giải Nhất',
          2: 'Giải Nhì',
          3: 'Giải Ba',
          4: 'Giải Khuyến Khích 1',
          5: 'Giải Khuyến Khích 2'
        },
        max4dCustom: {
          1: 'Giải Nhất (1 KQ)',
          2: 'Giải Nhì (2 KQ)',
          3: 'Giải Ba (3 KQ)'
        }
      },
      gameName: {
        max4d: 'Max4D thường',
        max4dcombine4: 'Max4D tổ hợp 4',
        max4dcombine6: 'Max4D tổ hợp 6',
        max4dcombine12: 'Max4D tổ hợp 12',
        max4dcombine24: 'Max4D tổ hợp 24'
      },
      specialNumber: false,
      specialNumberValue: 0,
      isUpdatePrize: false,
      modalGenerate: false,
      modalGenerateTitle: '',
      startPeriod: '',
      startTime: null,
      dayCount: 2
    };
  },
  computed: {
    paramsList() {
      return {
        params: {
          limit: this.perPage,
          offset: this.currentPage,
          // offset: 5,
          from: this.filter_from_period,
          to: this.filter_to_period,
          period_no: this.filter_period,
          returned_reward: this.returnRewardFilterValue
        }
      };
      // return {};
    },
    footerTableInfo() {
      const { currentPage, perPage, rows } = this;

      return `Showing ${(currentPage - 1) * perPage + 1}-${currentPage * perPage > rows ? rows : currentPage * perPage}
        of ${rows}.`;
    },
    gameHanlder() {
      return convertArrayToObject(this.allGameFilter, 'value');
    },
    modalTitle() {
      return `Kết quả quay số ${this.gameHanlder[this.filterGame]?.text || '?'}`;
    },
    modalTitleUpdate() {
      return `Cập nhật kết quả quay số ${this.gameHanlder[this.filterGame]?.text || '?'}`;
    },
    isDisableSaveUpdate() {
      const lengthResult = this.editedItem.lottery_result.length;
      const gameKey = this.gameHanlder[this.filterGame].handler;
      let checkGame = false;
      switch (gameKey) {
        case 'max3d':
        case 'max3d+':
        case 'max4d':
          checkGame =
            (this.$refs.resultMaxListNumberEdit && this.$refs.resultMaxListNumberEdit.arrError.length > 0) ||
            !this.$refs.resultMaxListNumberEdit;
          break;
        case 'power':
          checkGame = lengthResult !== 6 || !this.specialNumberValue;
          break;
        case 'mega':
          checkGame = lengthResult !== 6;
          break;
        default:
          checkGame = lengthResult !== 20;
      }
      if (this.isUpdatePrize && !checkGame) {
        return false;
      }
      return checkGame;
    },
    sortEditNumber() {
      return [...this.editedItem.lottery_result].sort((a, b) => a - b);
    },
    sortEditNumberPower() {
      return this.editedItem.lottery_result.slice(0, 6).sort((a, b) => a - b);
    }
  },
  created() {
    this.initData();
  },
  updated() {
    // console.log(this.$refs.resultMaxListNumberEdit);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Configs'
      },
      { title: 'Game Result', route: 'game-result' }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    async initData() {
      this.isLoading = true;

      //   ApiService.setHeader();

      const allGame = await ApiService.get('allgame');
      const { data, status } = allGame.data || {};
      if (status) {
        this.allGameFilter = data;
        this.filterGame = data[0].value;
        this.fetchData();
      }

      this.isLoading = false;
    },
    async fetchData() {
      if (!this.filterGame) return;
      this.isLoading = true;
      // if (this.filterGame !== null) {
      //   this.isFilterByGame = false;
      // } else {
      //   this.isFilterByGame = true;
      // }

      //   ApiService.setHeader();

      try {
        const res = await ApiService.query(`/periods/${this.filterGame}`, this.paramsList);
        const { data, status } = res.data || {};
        if (status) {
          this.items = data.period_list;
          this.rows = data.total;
        }
      } catch (error) {
        this.$bvToast.toast(`${error?.message || "Can't fetch data! Try again"}`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      } finally {
        this.isLoading = false;
      }
    },

    async handleViewItem(item) {
      this.dialog = true;
      this.viewItemIndex = this.items.indexOf(item);
      this.viewItem = {
        ...item
      };
      this.prizeItem(item);
    },
    async handleRegenerateResult(item) {
      try {
        await ApiService.put(`/periods/${item.game_id}/${item.id}`, { regenerate: true });
        this.$bvToast.toast(`Request sent`, {
          title: null,
          variant: 'success',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      } catch (error) {
        this.$bvToast.toast(`${error?.message || 'Generate result fail! Try again'}`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      }
    },
    handlePopupRegeneratePeriod() {
      this.modalGenerate = true;
      this.modalGenerateTitle = `Generate ${this.gameHanlder[this.filterGame].text} periods`;
    },
    async handleRegeneratePeriod(bvModalEvt) {
      // console.log(item);
      // this.isLoading = true;
      bvModalEvt.preventDefault();
      let valid = await this.$validator.validate();
      if (!valid) {
        return;
      }
      let params = {
        period_no: this.startPeriod,
        lottery_time: new Date(this.startTime).toISOString(),
        day_count: this.dayCount || (this.gameHanlder[this.filterGame] == 'keno' ? 2 : 20)
      };
      try {
        await ApiService.post(`/periods/${this.filterGame}`, params);
        this.$bvToast.toast(`Request sent`, {
          title: null,
          variant: 'success',
          autoHideDelay: 3000,
          noCloseButton: true
        });
        this.modalGenerate = false;
        this.modalGenerateTitle = '';
        this.startPeriod = '';
        this.startTime = null;
        this.dayCount = 2;
      } catch (error) {
        this.$bvToast.toast(`${error?.message || 'Generate period fail! Try again'}`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      }
      // } finally {
      //   this.isLoading = false;
      // }
    },
    handleEdit(item) {
      const gameKey = this.gameHanlder[this.filterGame].handler;

      this.dialogUpdate = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));

      if (['max3d', 'max3d+'].includes(gameKey) && this.editedItem.lottery_result.length === 0) {
        this.editedItem.lottery_result = [
          [...Array(2).fill('')],
          [...Array(4).fill('')],
          [...Array(6).fill('')],
          [...Array(8).fill('')]
        ];
      }

      if (gameKey === 'power') {
        this.specialNumberValue = this.editedItem.lottery_result.pop();
      } else {
        this.specialNumberValue = 0;
      }

      this.prizeItem(item);
    },
    prizeItem(item) {
      if (item.prize_count) {
        let prizeData = [];
        const gameKey = this.gameHanlder[this.filterGame].handler;
        if (['power', 'mega'].includes(gameKey)) {
          const listKeyPriority = ['jackpot_1', 'jackpot_2', 'first_prize', 'second_prize', 'third_prize'];
          listKeyPriority.forEach((keyPrize) => {
            if (!isNaN(item.prize_count[keyPrize])) {
              prizeData.push({
                key: keyPrize,
                title: this.prizeTitle[keyPrize],
                count: item.prize_count[keyPrize],
                amount: item[keyPrize] || ''
              });
            }
          });
        } else if (gameKey === 'max4d') {
          const defaultPrizeCount = {
            max4d: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0
            },
            max4dcombine24: {
              1: 0,
              2: 0,
              3: 0
            },
            max4dcombine12: {
              1: 0,
              2: 0,
              3: 0
            },
            max4dcombine6: {
              1: 0,
              2: 0,
              3: 0
            },
            max4dcombine4: {
              1: 0,
              2: 0,
              3: 0
            }
          };

          if (!item.prize_count[gameKey]) {
            item.prize_count = defaultPrizeCount;
          }
          const listKeyPriority = ['max4d', 'max4dcombine4', 'max4dcombine6', 'max4dcombine12', 'max4dcombine24'];
          listKeyPriority.forEach((maxGameKey) => {
            const maxGameValue = item.prize_count[maxGameKey];
            prizeData.push({
              name: this.gameName[maxGameKey],
              maxGameKey,
              data: Object.entries(maxGameValue).map(([key, value]) => ({
                key,
                title: this.prizeTitle[maxGameKey === 'max4d' ? 'max4d' : 'max4dCustom'][key],
                count: value
              }))
            });
          });
        } else {
          const defaultPrizeCount = {
            max3d: {
              1: 0,
              2: 0,
              3: 0,
              4: 0
            },
            'max3d+': {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0
            }
          };
          if (!item.prize_count[gameKey]) {
            item.prize_count = defaultPrizeCount;
          }

          Object.entries(item.prize_count[gameKey]).forEach(([key, value]) => {
            prizeData.push({
              key,
              title: this.prizeTitle[gameKey][key],
              count: value,
              amount: ''
            });
          });
        }

        this.prize = prizeData;
        // Object.entries().
      } else {
        this.prize = [];
      }
    },
    handleNumberUpdate(item) {
      const gameKey = this.gameHanlder[this.filterGame].handler;
      const index = this.editedItem.lottery_result.indexOf(this.mixNumber(item));

      if (this.specialNumberValue == item) {
        this.specialNumberValue = 0;
        if (this.editedItem.lottery_result.length == 6) {
          this.specialNumber = true;
        }
        return;
      } else {
        if (this.specialNumber) {
          this.specialNumberValue = item;
          if (index > -1) {
            this.editedItem.lottery_result.splice(index, 1);
          }
          return;
        }
      }

      if (index > -1) {
        this.editedItem.lottery_result.splice(index, 1);
      } else {
        const lengthResult = this.editedItem.lottery_result.length;

        let isUpdate = false;
        switch (gameKey) {
          case ['max3d', 'max3d+', 'max4d'].includes(gameKey):
            break;
          // if (lengthResult < 6) isUpdate = true;
          // break;
          case 'power':
          case 'mega':
            if (lengthResult < 6) isUpdate = true;
            break;
          default:
            if (lengthResult < 20) isUpdate = true;
        }
        if (isUpdate) {
          this.editedItem.lottery_result.push(this.mixNumber(item));
        }
      }
    },
    async handleSaveUpdate() {
      const res = await Swal.fire({
        title: `Are you sure update period result `,
        html: `<div><b>Kỳ quay #${this.editedItem.period_no}</b> <br/> Ngày: <br>${this.formatDateForViewFunc(
          this.editedItem.lottery_time
        )}</br></div>`,
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true
      });
      if (res.isConfirmed) {
        try {
          let { lottery_result, id, game_id, prize_count } = this.editedItem;
          const gameKey = this.gameHanlder[this.filterGame].handler;
          lottery_result = lottery_result.sort((a, b) => a - b);

          let params = {
            lottery_result,
            prize_count: {}
          };

          if (this.prize.length) {
            if (['power', 'mega'].includes(gameKey)) {
              const specialList = ['jackpot_1', 'jackpot_2'];
              this.prize.forEach(({ key, count, amount }) => {
                if (key) {
                  params.prize_count[key] = +count;
                }

                if (specialList.includes(key)) {
                  params[key] = +amount;
                }
              });
            } else if (gameKey === 'max4d') {
              this.prize.forEach(({ maxGameKey, data }) => {
                if (maxGameKey) {
                  params.prize_count[maxGameKey] = data.reduce(
                    (newObj, item) => ({ ...newObj, [item.key]: +item.count }),
                    {}
                  );
                }
              });
            } else {
              params.prize_count = {
                ...prize_count,
                [gameKey]: {}
              };
              this.prize.forEach(({ key, count }) => {
                if (key) {
                  params.prize_count[gameKey][key] = +count;
                }
              });
            }
          }

          if (gameKey === 'power') {
            lottery_result.push(this.specialNumberValue);
          }

          if (gameKey === 'max4d') {
            params.lottery_result[3][0] = 'X' + params.lottery_result[0][0].slice(1);
            params.lottery_result[4][0] = 'XX' + params.lottery_result[0][0].slice(2);
          }

          const res = await ApiService.put(`/periods/${game_id}/${id}`, params);

          if (res.status === 204) {
            // this.items[this.editedIndex].lottery_result = lottery_result;
            this.fetchData();
            this.specialNumberValue = 0;
            this.dialogUpdate = false;
            this.$bvToast.toast(`Update success`, {
              title: null,
              variant: 'success',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          }
        } catch (error) {
          this.$bvToast.toast(error?.message || `Update fail! Try again.`, {
            title: null,
            variant: 'error',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        }
      }
    },
    formatDateForViewFunc(time) {
      return formatDateForView(time);
    },
    handleHideUpdate() {
      this.specialNumber = false;
      this.isUpdatePrize = false;
    },
    handleChangePrize() {
      this.isUpdatePrize = true;
    },
    onChangeFilterGame() {
      this.currentPage = 1;
      this.filter_period = '';
      this.filter_from_period = '';
      this.filter_to_period = '';
      this.fetchData();
    },
    onChangeReturnReward() {
      this.currentPage = 1;

      this.fetchData();
    }
  },
  watch: {
    filterGame: function (newValue) {
      const gameKey = this.gameHanlder[newValue].handler;

      switch (gameKey) {
        // case ['max3d', 'max3d+', 'max4d'].includes(gameKey):
        //   break;
        case 'mega':
          this.listAllNumber = [...Array(45)].map((x, index) => {
            return this.mixNumber(index + 1);
          });
          break;
        case 'power':
          this.listAllNumber = [...Array(55)].map((x, index) => {
            return this.mixNumber(index + 1);
          });
          break;
        default:
          this.listAllNumber = [...Array(80)].map((x, index) => {
            return this.mixNumber(index + 1);
          });
      }
    },
    'editedItem.lottery_result': function (newValue) {
      if (this.gameHanlder[this.filterGame].handler === 'power' && newValue.length === 6 && !this.specialNumberValue) {
        this.specialNumber = true;
      }
    }
  }
  // updated() {
  //   console.log(this.gameHanlder);
  // }
};
</script>

<style lang="scss" scoped>
.lotteryInfo {
  font-size: 16px;
  b {
    font-weight: 700;
  }
}

.customMr5 {
  margin-right: -7px;
}

.mh-90 {
  min-height: 90px;
}

.mh-50 {
  min-height: 50px;
}

.controlNumber {
  &--keno {
    min-height: 210px;
    // overflow-x: auto;
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 30px);
      justify-content: center;
      grid-gap: 8px;

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, 35px);
        grid-gap: 7px 12px;
      }

      .customNumber .checkbox-custom-input--checked {
        font-weight: 600 !important;
      }
    }
  }

  &--powerMega {
    min-height: 210px;
    // overflow-x: auto;
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 30px);
      justify-content: center;
      grid-gap: 8px;

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, 35px);
        grid-gap: 7px 12px;
      }

      .customNumber .checkbox-custom-input--checked {
        font-weight: 600 !important;
      }
    }
  }
}

.boxPrize {
  background-color: rgba(247, 237, 226, 0.5);
  padding: 10px;
  border-radius: 5px;
  font-size: 15px !important;
  .row {
    border-bottom: 1px solid #ede8d3;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0.35rem;
    }
  }
}
.modalDetail {
  font-size: 15px !important;
}

.form-control {
  font-size: 15px !important;
}
</style>
