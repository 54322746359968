<template>
  <div
    :class="{
      'item--md': isMD,
      'item--sm': isSM,
      'item--active': isActive,
      customForResult: isCustomForResult
    }"
    class="item"
  >
    {{ getValue }}
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number
    },
    numberMix: {
      type: Boolean,
      default: false
    },

    isActive: {
      type: Boolean,
      default: true
    },
    isMD: {
      type: Boolean,
      default: false
    },
    isSM: {
      type: Boolean,
      default: false
    },
    isCustomForResult: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue() {
      if (this.numberMix) return this.mixNumber(this.number);
      return isNaN(this.number) ? '*' : this.number || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
%box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  @extend %box-center;
  //   display: inline-block;

  border-radius: 999px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  color: #333333;
  background-color: #f7f7f7;
  font-size: 1rem;

  &--active {
    background-color: #d31904 !important;
    color: #ffffff;
  }
  &--md {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  &--sm {
    width: 30px !important;
    height: 30px !important;
    font-size: 16px !important;
  }

  &.customForResult {
    // width: 30px;
    // height: 30px;
    font-size: 16px;
    color: #333333;
    border: solid 1px #fccb1a;
    background-image: linear-gradient(138deg, #fff0ba, #ffbd0a);
    @media screen and (min-width: 992px) {
      width: 40px;
      height: 40px;
      font-size: 18px;
    }
  }

  &.specialNumber {
    background-color: #00509d !important;
    background-image: none !important;
    color: #ffffff !important;
    border: none !important;
  }

  @media screen and (min-width: 992px) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}
</style>
